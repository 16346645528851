import Button from "app/components/Button";
import LinkButton from "app/components/Button/LinkButton";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import {
  Calendar,
  ClassesActive,
  ClassesInactive,
  Community,
  Heart,
  HeartFilled,
  History,
  Home,
  InstructorsInactive,
  InstructorsActive,
  Library,
  Party,
  ProgramsActive,
  ProgramsInactive,
  Playlist,
} from "app/components/Icon";
import { ScreenLtMd } from "app/components/MediaQueries";
import { ANALYTICS_ELEMENT_TYPE, ANALYTICS_MODULE } from "constants/index";
import {
  setSideNavbarWidthAction,
  shrinkOrExpandSideNavbarAction,
} from "modules/components";
import { userClickedExternalLinkAction } from "modules/content";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { matchPath } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { clickLink, elementSelected } from "services/typewriter/segment";
import transition from "styled-transition-group";
import { getAnalyticsLocationFromPath } from "helpers/analytics";
import {
  useSideNavbarSettings,
  useNavbarHeight,
  useUserSubscription,
} from "modules/selectors";
import { useUserAccessType } from "hooks/Subscriptions/useUserAccessType";
import { AccessType } from "services/graphql";
import { Routes } from "constants/routes";
import {
  LARGE_CUSTOM_BREAKPOINT,
  MOBILE_CUSTOM_BREAKPOINT,
} from "../Navbar/constants";
import ExpandedSideNavLink from "./ExpandedSideNavLink";
import FollowedCategories from "./FollowedCategories";
import { LimitLineH5, LimitLineP2, LineSeparator } from "./lines";
import ShrunkenSideNavLink from "./ShrunkenSideNavLink";
import SideNavLink from "./SideNavLink";
import { GradientLabel } from "./GradientLabel";

const FadeIn = transition.div`
  padding-bottom: 16px;

  &:exit-active {
    opacity: 0;
  }

  &:enter {
    opacity: 0;
  }

  &:enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
`;

const EXPANDED_WIDTH = 300;
const SHRUNKEN_WIDTH = 96;

const OVERLAY_SIDE_NAVBAR_ROUTES = [
  "/class/:id",
  "/class/:id/preview",
  "/programs/:slug",
];

interface Props {
  isAnonymous: boolean;
}

const SideNavbar = ({ isAnonymous }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const navbarHeight = useNavbarHeight();
  const {
    isExpanded: isSideBarExpanded,
    width: widthOfSideNavbar,
  } = useSideNavbarSettings();
  const {
    isSubscriptionPaused,
    isSubscriptionActive,
    hasSubscription,
  } = useUserSubscription();
  const userAccessType = useUserAccessType();

  const goToLink = ({
    path,
    component,
  }: {
    path: string;
    component?: string;
  }) => {
    if (!path) {
      return;
    }

    clickLink({
      destination: path,
      in_component: component || "SideNavbar",
      path: location?.pathname,
    });

    const elementSelectedData = {
      feature: "navigation",
      element_type: ANALYTICS_ELEMENT_TYPE.button,
      module: ANALYTICS_MODULE.navigation_bar,
      location: getAnalyticsLocationFromPath(location.pathname),
    };

    if (path.includes("community")) {
      elementSelected({
        ...elementSelectedData,
        element_name: "Community",
      });
    } else if (path.includes("library")) {
      elementSelected({
        ...elementSelectedData,
        element_name: "Library",
      });
    } else if (path.includes("dashboard")) {
      elementSelected({
        ...elementSelectedData,
        element_name: "Home",
      });
    } else if (path.includes("playlists")) {
      elementSelected({
        ...elementSelectedData,
        element_name: "Playlists",
      });
    }

    history.push(path);

    if (isSideBarExpanded && showOverlay) {
      dispatch(shrinkOrExpandSideNavbarAction(false));
    }
  };

  const isLargeScreen = useMediaQuery({
    minDeviceWidth: LARGE_CUSTOM_BREAKPOINT,
  });

  const isMobileScreen = useMediaQuery({
    maxDeviceWidth: MOBILE_CUSTOM_BREAKPOINT,
  });

  const showOverlay = useMemo(
    () =>
      isMobileScreen ||
      OVERLAY_SIDE_NAVBAR_ROUTES.some(path =>
        matchPath(location.pathname, {
          path,
          exact: true,
        })
      ),
    [isMobileScreen, location.pathname]
  );

  useEffect(() => {
    dispatch(shrinkOrExpandSideNavbarAction(isLargeScreen && !showOverlay));
  }, [dispatch, isLargeScreen, showOverlay]);

  useEffect(() => {
    let width = isSideBarExpanded ? EXPANDED_WIDTH : SHRUNKEN_WIDTH;
    if (isMobileScreen) {
      width = 0;
    }
    // If a mobile user has expanded the sidenav
    if (isMobileScreen && isSideBarExpanded) {
      width = EXPANDED_WIDTH;
    }
    // When showing overlays, we either show or hide the bar
    if (showOverlay) {
      width = isSideBarExpanded ? EXPANDED_WIDTH : 0;
    }
    dispatch(setSideNavbarWidthAction(width));
  }, [dispatch, isMobileScreen, showOverlay, isSideBarExpanded]);

  const isPlaylistsActive = Boolean(
    matchPath(location.pathname, {
      path: "/playlists",
    })
  );

  const accessClassPathMap = {
    [AccessType.Free]: Routes.library__free_classes,
    [AccessType.Basic]: Routes.library__basic_classes,
    [AccessType.Standard]: Routes.library__classes,
    [AccessType.Premium]: Routes.library__classes,
  };

  return (
    <>
      {showOverlay && isSideBarExpanded && (
        <Div
          width="100%"
          height="100%"
          position="fixed"
          bg="rgba(0, 0, 0, 0.4)"
          // right under navbar
          zIndex={9998}
          onClick={() => dispatch(shrinkOrExpandSideNavbarAction(false))}
        />
      )}
      <Flex
        pt={`${navbarHeight + 16}px`}
        px={isSideBarExpanded ? 2 : 0}
        width={widthOfSideNavbar}
        role="navigation"
        transition="left 150ms ease-in-out, width 150ms ease-in-out"
        flexDirection="column"
        boxShadow="4px 10px 20px rgba(0, 0, 0, 0.05)"
        position={showOverlay ? "absolute" : "relative"}
        left={isSideBarExpanded || !showOverlay ? 0 : -300}
        top={0}
        bg="white"
        height="100%"
        zIndex={9999}
        overflow="auto"
      >
        <FadeIn
          unmountOnExit
          timeout={150}
          in={isSideBarExpanded || showOverlay}
        >
          {!isAnonymous && (
            <ExpandedSideNavLink
              path="/dashboard"
              goToLink={goToLink}
              LinkIcon={Home}
              ActiveLinkIcon={Home}
            >
              <LimitLineH5>Home</LimitLineH5>
            </ExpandedSideNavLink>
          )}
          <ExpandedSideNavLink
            path={accessClassPathMap[userAccessType]}
            goToLink={goToLink}
            LinkIcon={ClassesInactive}
            ActiveLinkIcon={ClassesActive}
          >
            <LimitLineH5>Classes</LimitLineH5>
          </ExpandedSideNavLink>
          <ExpandedSideNavLink
            path="/library/programs"
            goToLink={goToLink}
            LinkIcon={ProgramsInactive}
            ActiveLinkIcon={ProgramsActive}
          >
            <LimitLineH5>Programs</LimitLineH5>
          </ExpandedSideNavLink>
          <ExpandedSideNavLink
            path="/library/categories"
            goToLink={goToLink}
            LinkIcon={Library}
            ActiveLinkIcon={Library}
          >
            <LimitLineH5>Categories</LimitLineH5>
          </ExpandedSideNavLink>
          <ExpandedSideNavLink
            path="/library/instructors"
            goToLink={goToLink}
            LinkIcon={InstructorsInactive}
            ActiveLinkIcon={InstructorsActive}
          >
            <LimitLineH5>Instructors</LimitLineH5>
          </ExpandedSideNavLink>
          <ExpandedSideNavLink
            path="/playlists"
            goToLink={goToLink}
            LinkIcon={Playlist}
            active={isPlaylistsActive}
          >
            <LimitLineH5>Playlists</LimitLineH5>
          </ExpandedSideNavLink>
          {!isAnonymous && (
            <ExpandedSideNavLink
              path="/schedule"
              goToLink={goToLink}
              LinkIcon={Calendar}
            >
              <LimitLineH5>Schedule</LimitLineH5>
            </ExpandedSideNavLink>
          )}
          {!isAnonymous && (
            <ExpandedSideNavLink
              path="/saved"
              goToLink={goToLink}
              LinkIcon={Heart}
              ActiveLinkIcon={HeartFilled}
            >
              <LimitLineH5>Saved</LimitLineH5>
            </ExpandedSideNavLink>
          )}
          {!isAnonymous && (
            <ExpandedSideNavLink
              path="/history"
              goToLink={goToLink}
              LinkIcon={History}
            >
              <LimitLineH5>History</LimitLineH5>
            </ExpandedSideNavLink>
          )}
          <ExpandedSideNavLink
            path="/party"
            goToLink={goToLink}
            LinkIcon={Party}
          >
            <LimitLineH5>Party</LimitLineH5>
          </ExpandedSideNavLink>
          <ExpandedSideNavLink
            path="/community"
            goToLink={goToLink}
            LinkIcon={Community}
          >
            <LimitLineH5>Community</LimitLineH5>
            <GradientLabel label="NEW!" />
          </ExpandedSideNavLink>
          <FollowedCategories goToLink={goToLink} />
          <LineSeparator />
          <Flex
            px="18px"
            width="100%"
            height="32px"
            alignItems="center"
            mb={2}
            color="black"
          >
            <LimitLineH5>More From STEEZY</LimitLineH5>
          </Flex>
          <Flex flexDirection="column" mb={3}>
            <SideNavLink
              href="https://blog.steezy.co/"
              onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
                e.preventDefault();
                dispatch(
                  userClickedExternalLinkAction({
                    url: "https://blog.steezy.co/",
                  })
                );
              }}
            >
              <LimitLineP2>Blog</LimitLineP2>
            </SideNavLink>
            <SideNavLink
              href="https://www.facebook.com/groups/steezystudiomembers/"
              onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
                e.preventDefault();
                dispatch(
                  userClickedExternalLinkAction({
                    url: "https://www.facebook.com/groups/steezystudiomembers/",
                  })
                );
              }}
            >
              <LimitLineP2>Facebook Group</LimitLineP2>
            </SideNavLink>
            <SideNavLink
              href="https://steezy.zendesk.com/hc/en-us"
              onClick={(e: React.MouseEvent<HTMLLinkElement, MouseEvent>) => {
                e.preventDefault();
                dispatch(
                  userClickedExternalLinkAction({
                    url: "https://steezy.zendesk.com/hc/en-us",
                  })
                );
              }}
            >
              <LimitLineP2>Contact Us</LimitLineP2>
            </SideNavLink>
          </Flex>
          {isAnonymous && (
            // Is shown on top nav if screen greater than MD
            <ScreenLtMd>
              <Flex justifyContent="flex-end" flexDirection="column">
                <Button
                  bg="black"
                  hoverBg="monochrome.2"
                  py={[2, 3]}
                  px={[3, 4]}
                  onClick={() => goToLink({ path: "/login" })}
                >
                  Log In
                </Button>
                <Button
                  mt={2}
                  py={[2, 3]}
                  px={[3, 4]}
                  onClick={() => goToLink({ path: "/register" })}
                >
                  Sign Up
                </Button>
              </Flex>
            </ScreenLtMd>
          )}
          {!isAnonymous && !isSubscriptionActive && !isSubscriptionPaused && (
            <ScreenLtMd>
              <LinkButton
                fontSize="12px"
                letterSpacing="2px"
                py={3}
                px={4}
                width="100%"
                borderRadius="0"
                fontWeight="medium"
                to="/checkout"
              >
                {hasSubscription ? "Reactivate" : "Subscribe"}
              </LinkButton>
            </ScreenLtMd>
          )}
        </FadeIn>
        <FadeIn
          unmountOnExit
          timeout={150}
          in={!(isSideBarExpanded || showOverlay)}
        >
          {!isAnonymous && (
            <ShrunkenSideNavLink
              LinkIcon={Home}
              ActiveLinkIcon={Home}
              path="/dashboard"
              goToLink={goToLink}
            >
              Home
            </ShrunkenSideNavLink>
          )}
          <ShrunkenSideNavLink
            path="/library/classes"
            LinkIcon={ClassesInactive}
            ActiveLinkIcon={ClassesActive}
            goToLink={goToLink}
          >
            Classes
          </ShrunkenSideNavLink>
          <ShrunkenSideNavLink
            path="/library/programs"
            LinkIcon={ProgramsInactive}
            ActiveLinkIcon={ProgramsActive}
            goToLink={goToLink}
          >
            Programs
          </ShrunkenSideNavLink>
          <ShrunkenSideNavLink
            path="/library/categories"
            LinkIcon={Library}
            ActiveLinkIcon={Library}
            goToLink={goToLink}
          >
            Categories
          </ShrunkenSideNavLink>
          <ShrunkenSideNavLink
            path="/library/instructors"
            LinkIcon={InstructorsInactive}
            ActiveLinkIcon={InstructorsActive}
            goToLink={goToLink}
          >
            Instructors
          </ShrunkenSideNavLink>
          <ShrunkenSideNavLink
            active={isPlaylistsActive}
            path="/playlists"
            LinkIcon={Playlist}
            ActiveLinkIcon={Playlist}
            goToLink={goToLink}
          >
            Playlists
          </ShrunkenSideNavLink>

          {!isAnonymous && (
            <ShrunkenSideNavLink
              path="/schedule"
              LinkIcon={Calendar}
              ActiveLinkIcon={Calendar}
              goToLink={goToLink}
            >
              Schedule
            </ShrunkenSideNavLink>
          )}
          {!isAnonymous && (
            <ShrunkenSideNavLink
              path="/saved"
              LinkIcon={Heart}
              ActiveLinkIcon={HeartFilled}
              goToLink={goToLink}
            >
              Saved
            </ShrunkenSideNavLink>
          )}
          {!isAnonymous && (
            <ShrunkenSideNavLink
              path="/history"
              LinkIcon={History}
              goToLink={goToLink}
            >
              History
            </ShrunkenSideNavLink>
          )}
          <ShrunkenSideNavLink
            path="/party"
            LinkIcon={Party}
            goToLink={goToLink}
          >
            Party
          </ShrunkenSideNavLink>
          <ShrunkenSideNavLink
            path="/community"
            LinkIcon={Community}
            goToLink={goToLink}
          >
            Community
            <GradientLabel label="NEW!" />
          </ShrunkenSideNavLink>
        </FadeIn>
      </Flex>
    </>
  );
};

SideNavbar.propTypes = {
  isAnonymous: PropTypes.bool.isRequired,
};

export default SideNavbar;
