import { useEffect, useState } from "react";
import { LocalStorageKeys } from "../constants/localStorage";
import firebase from "../config/firebase-config";
import { error as segmentError } from "../services/typewriter/segment";

export function getStoredGCLID() {
  return localStorage.getItem(LocalStorageKeys.GCLID);
}

export function setStoredGCLID(gclid: string | null) {
  try {
    if (gclid) {
      localStorage.setItem(LocalStorageKeys.GCLID, gclid);
    } else {
      localStorage.removeItem(LocalStorageKeys.GCLID);
    }
  } catch (error) {
    console.error("Error managing GCLID in localStorage:", error);
  }
}

export function useGCLIDTracking() {
  const [ready, setReady] = useState(false);
  const { currentUser } = firebase.auth();

  useEffect(() => {
    const handleGCLID = async () => {
      if (!currentUser) {
        return;
      }

      // Get GCLID from current URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const gclid = urlParams.get("gclid");

      if (gclid) {
        try {
          // Store in localStorage
          setStoredGCLID(gclid);

          // Store in Firebase
          const db = firebase.database();
          const gclidsRef = db.ref(
            `/users_subscriptions/${currentUser.uid}/advertising_data_google/gclids`
          );

          await gclidsRef.push({
            gclid,
            timestamp: Date.now(),
            landing_url: window.location.href,
          });
        } catch (error) {
          console.error("Error storing GCLID:", error);
          segmentError({
            message: error instanceof Error ? error.message : String(error),
            platform: "web",
            location: "useGCLIDTracking",
          });
        }
      }

      setReady(true);
    };

    handleGCLID();
  }, [currentUser]);

  return ready;
}
